import { Link } from "gatsby"
import React from "react"
import Layout from "../Components/Layout"

export default function Home() {
  return (
    <Layout>
      <div style={{ margin: "3rem" }}>
        <h1>Hoppsan, något gick fel!</h1>
        <Link to="/">
          <p>Tryck här för att komma tillbaka till webbsidan!</p>
        </Link>
      </div>
    </Layout>
  )
}
